import React, { Component } from 'react';
// redux method to subscribe class
import { connect } from 'react-redux';
import StartActivityDetails from "./StartActivityDetails"
import StartActivityListViewDetails from "./StartActivityListViewDetails";
import Moment from 'moment'
// reactstrap components
import { Label } from 'reactstrap';
// SweetAlert
import SweetAlert from 'react-bootstrap-sweetalert'
import ActivityForm from '../../ActivityForm';
import LifeSherpaLoading from '../../LifeSherpaLoading';
// Custom Components
import SetCurrentStatusForm from "./SetCurrentStatusForm";
// Material UI Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import DialogTitle from "@mui/material/DialogTitle";
// Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SherpaLogo from '../../../assets/img/life-sherpa-logo-retina.png';
import SherpaCamera from "../../SherpaCamera";
import AchievementTimer from "./AchievementTimer";
import ActivityCourse from "../../Courses/ActivityCourse";
// Actions
import {
  getUserStatusById,
  getClientStatusDataById,
  getCourse,
  getWPQ,
  pauseAcivity,
  EndActivity,
  StartStep,
  selectActivityId,
  EndStep,
  SkipStep,
  cancelActivity,
  getPokeForm,
  setPokeFormForGroup,
  setSelectedStep,
  postStepsNotesForm,
  savePokrFormForClients,
  clearActivityDataOnClose,
  clearPokeFormData,
  handleShowPhotoCamera,
  removeAPIError,
  handleExecuteEncounterActivity,
  setSelectedMemberId,
  getactivitiesCalendar,
  setPokeFormData,
  handleShowHelpPanel,
  handleShowActivityCourse,
  clearCourseData,
  getActivitiesList,
  getActivitiesListN,
  setClientActivitiesType,
  showClientPanel, changeRole,
  getUserPendingWorkflowList,
  getUserSubWorkflowHistoryDetail,
  getSubWorkflowHistoryDetail,
  getUserWorkflowHistoryDetail,
  getWorkflowHistoryDetail
} from '../../../actions'
import { validURL, openPopup, lsTextSpeech } from '../../../helpers/helpers'
import ActivityHelpContacts from '../../LifesherpaContacts/ActivityHelpContacts';
import FormPage from './FormPage';

class StartActivityListView extends Component {
  state = {
    stepNo: 0,
    closeAlert: false,
    stepslist: [],
    stepsId: [],
    activity: {},
    open: false,
    showForm: false,
    evaluationData: null,
    stepsNotesData: null,
    labelValue: 0,
    timerTime: null,
    timerStart: null,
    timeOutAlert: false,
    showRewards: false,
    nextStep: false,
    showSelfEvaluationform: true,
    countDownInterval: 0,
    countDownStart: false,
    showEvent: false,
    eventDate: null,
    addEvent: false,
    title: '',
    note: '',
    nextdisable: false,
    selectedThumb: "up",
    openedItem: [],
    viewStyle: "flow",
    stepsDisabled: [],
    activeStep: -1,
    checkList: {},
    isNext: true,
    StatusForm: true,
    statusList: [],
    statusData: null,
    openedStep: -1,
    openCourseAlert: false,
    responseSubmitted: false,
    showPictureCamera: false,
    courseOpened: false,
    course_completion_status: undefined
  }

  handlePreviousCourseStatus(course_completion_status){
      this.setState({course_completion_status})
  }

  updateCheckList = (checkName) => {
    let s = { ...this.state };
    s.checkList[checkName] = true;
    this.setState(s);
  }

  setActiveStep = (stepNo) => {
    this.setState({ ...this.state, activeStep: stepNo })
  }

  setStepDisabled = (stepNo) => {
    let s = { ...this.state };
    let index = s.stepsDisabled.findIndex(li => li == stepNo);
    if (index == -1) {
      s.stepsDisabled.push(stepNo);
      this.setState(s);
    }

  }

  setStepEnabled = (stepNo) => {
    let s = { ...this.state };
    let index = s.stepsDisabled.findIndex(li => li == stepNo);
    if (index >= 0) {
      s.stepsDisabled.splice(index, 1);
      this.setState(s);
    }
  }

  setOpenSteps = (p) => {
    let { stepslist, activity } = this.props;
    let lstate = { ...this.state };

    if (p == lstate.openedStep) {
      lstate.openedStep = -1;
    } else {
      lstate.openedStep = p;
      // auto speack step title
      if(activity && activity.autoTTSEnabled) {
        let step = stepslist.find(step => step.position == p);
        if (step && step.title) {
          lsTextSpeech(step.title)
        }
      } 
    }

    if (stepslist) {
      let step = stepslist.find(step => step.position == p);
      if (step && step.countDownInterval) {
        lstate.countDownInterval = step.countDownInterval;
      }
    }

    this.setState(lstate);
  }

  componentDidMount() {
    console.group("%c[ActivityPreview]%c Rendered activity preview dialog popup page", "color:black", "color:green");
    this.props.handleShowHelpPanel({show: false, url: null})
    let index = this.props.startActivity && this.props.startActivity.achievement ? this.props.activityTimer.findIndex((time) => time.routineId === this.props.startActivity.achievement.routineId) : -1
    if (index > -1) {
      this.setState({
        timerOn: true,
        timerTime: this.state.timerTime,
        timerStart: Date.now() - this.state.timerTime - +this.props.activityTimer[index].timer,
      });
    } else {
      this.setState({
        timerOn: true,
        timerTime: this.state.timerTime,
        timerStart: Date.now() - this.state.timerTime
      });
    }
    this.intervalID = setInterval(
      () => this.setState({
        timerTime: Date.now() - this.state.timerStart
      }),
      1000
    );
    this.props.clearCourseData();
  }

  componentWillUnmount() {
    const {clientActivitiesType, clientPanel} = this.props
    const userOrgId = localStorage.getItem("selectedOrgId")
    const orgId = localStorage.getItem("orgId")
    const uid = localStorage.getItem("uid")
    const auth_uid = localStorage.getItem("auth_uid")
    console.groupEnd("%c Activity Priview Popup Closed", "color:red")
    clearInterval(this.intervalID);

    if (!clientPanel && clientActivitiesType === "Touchpoint") {
      localStorage.setItem('uid', auth_uid);
      this.props.setSelectedMemberId(null);
      this.props.setClientActivitiesType('Normal')
      this.props.changeRole({ role: 'admin', id: auth_uid })
      this.props.getUserPendingWorkflowList({userId: auth_uid, orgId})
    } else if (!clientPanel) {
      localStorage.setItem('uid', auth_uid);
      this.props.getUserPendingWorkflowList({userId: auth_uid, orgId})
    } else {
      this.props.getUserPendingWorkflowList({userId: uid, orgId: userOrgId})
    }
    //window.postMessage("Load Workflow List", window.location.origin);
  }

  componentDidUpdate(previousProps, previousState) {
    const { activityMessage, startActivity, selfEvaluationFormId, stepsCompletionList, shouldTakePicture, executeActivity, takePictureMessage, showSetStatusPopup, statusData, stepslist } = this.props;
    const { showPictureCamera, photoUrl, showForm } = this.state;
    const stepsCount = stepslist && stepslist && stepslist ? stepslist.length : null;
    const stepsCompletedList = stepsCompletionList.length > 0 ? stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped") : [];
    if (shouldTakePicture && stepsCompletedList.length == stepsCount && executeActivity && !showPictureCamera && activityMessage !== "Achievement ended successfully" && !photoUrl) {
      this.setState({ showPictureCamera: true, stepFormId: null, stepId: null, takePictureMessage: takePictureMessage });
    } else if((stepsCount === 0 || (stepsCount > 0 && stepsCompletedList.length == stepsCount ) ) && this.state.showSelfEvaluationform && !showSetStatusPopup && activityMessage !== "Achievement ended successfully") {
      if(statusData && !showForm) {
        this.props.setPokeFormData(statusData)
        this.setState({ showForm: true, nextStep: true, showSelfEvaluationform: false })
      } else if(selfEvaluationFormId && !showForm) {
        this.openSelfEvaluationForm(selfEvaluationFormId)
      }
    }
  }

  tick() {
    this.setState({
      sec: this.state.sec + 1
    });
  }

  handlecloseAlert = () => {
    const { stepslist, stepsCompletionList, activityMessage, shouldTakePicture, selfEvaluationFormId, selectedMember, clientActivitiesType, selectedGroup } = this.props;
    const uid = localStorage.getItem('uid')
    const auth_uid = localStorage.getItem('auth_uid')
    console.log("activityMessage --> ", activityMessage);
    if (this.props.executeActivity) {
      if (((stepslist.length == stepsCompletionList.length || stepslist.length === 0) && activityMessage == "Achievement ended successfully")) {
        this.handleCloseActivityPopup()
      } else if((stepslist.length == stepsCompletionList.length || stepslist.length === 0) && activityMessage == "Achievement resumed successfully") {
        this.handleCloseActivityPopup()
      } else if((stepslist.length == stepsCompletionList.length || stepslist.length === 0) && activityMessage == "Achievement resumed successfully") {
        this.handleCloseActivityPopup()
      } else {
        this.setState({ closeAlert: true })
      }
    } else {
      if (((stepslist.length == stepsCompletionList.length || stepslist.length === 0) && activityMessage == "Achievement ended successfully")) {
        this.handleCloseActivityPopup()
      } else if((stepslist.length == stepsCompletionList.length || stepslist.length === 0) && activityMessage == "Achievement resumed successfully") {
        this.handleCloseActivityPopup()
      } else if((stepslist.length == stepsCompletionList.length || stepslist.length === 0) && activityMessage == "Achievement resumed successfully") {
        this.handleCloseActivityPopup()
      } else {
        this.handleCancel()
      }
    }

    if (auth_uid === uid) {
      this.props.getActivitiesList(auth_uid, "", selectedGroup);
    } else {
      const {organization: orgId, groupId} = selectedMember || {};
      const userId = localStorage.getItem('uid');
      const activityType = clientActivitiesType === "Touchpoint" ? "Touchpoint" : null;
      this.props.getActivitiesListN({userId, orgId, groupId, filter:'', activityType});
    }
  }

  handleClose = () => {
    let achievementId = this.props.startActivity ? this.props.startActivity.achievementId : ''
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    let routineId = this.props.startActivity.achievement && this.props.startActivity.achievement && this.props.startActivity.achievement.routineId ? this.props.startActivity.achievement.routineId : ''
    let uid = localStorage.getItem('uid')

    if (this.props.startActivity && this.props.startActivity.achievement && this.props.startActivity.achievement.routine.isTimerEnabled) {
      this.props.pauseAcivity(achievementId, date, uid, routineId, this.state.timerTime)
    } else {
      this.props.pauseAcivity(achievementId, date, uid, '', null)
    }
    this.props.clearActivityDataOnClose();
    this.setState({ closeAlert: false, timeOutAlert: false })
    this.handleCloseActivityPopup();
  }

  handleCancel = () => {
    let id = this.props.startActivity ? this.props.startActivity.achievementId : ''
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    //if(this.props.activityType==="private") {
    //console.log('this.props.startActivity.achievement.routineId',this.props.startActivity)
    let routineId = this.props.startActivity && this.props.startActivity.achievement && this.props.startActivity.achievement.routineId ? this.props.startActivity.achievement.routineId : ''
    let uid = localStorage.getItem('uid')
    let auth_uid = localStorage.getItem('auth_uid')
    const {selectedMember, clientActivitiesType, selectedGroup} = this.props

    if (this.props.startActivity && this.props.startActivity.achievement && this.props.startActivity.achievement.routine && this.props.startActivity.achievement.routine.isTimerEnabled) this.props.cancelActivity(id, date, uid, routineId, this.state.timerTime)
    else this.props.cancelActivity(id, date, uid, null, null)
    //} 
    this.props.clearActivityDataOnClose();
    this.setState({ closeAlert: false, timeOutAlert: false })
    this.handleCloseActivityPopup();
  }


  handleEndActivity = () => {
    let achievementId = this.props.startActivity && this.props.startActivity.achievementId ? this.props.startActivity.achievementId : ""
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    let routineId = this.props.startActivity && this.props.startActivity.achievement && this.props.startActivity.achievement.routineId ? this.props.startActivity.achievement.routineId : ''
    let uid = localStorage.getItem('uid');
    let orgId = localStorage.getItem('selectedOrgId') || localStorage.getItem('orgId');

    if (this.props.startActivity && this.props.startActivity.achievement && this.props.startActivity.achievement.routine.isTimerEnabled) {
      this.props.EndActivity(achievementId, date, uid, routineId, this.state.timerTime, orgId)
    } else {
      this.props.EndActivity(achievementId, date, uid, '', null, orgId)
    }
    this.handleCloseActivityPopup()
  }
  handleCloseActivityPopup = () => {
    const userId = localStorage.getItem("uid");
    const orgId = localStorage.getItem("orgId")
    console.log(" Clearing execute activity data --> ");
    if(this.props.executeActivity && this.props.enableWorkflows) {
      this.refreshWorkflowExecutionHistoryDetail();
    }
    this.hanldeCloseExecuteEncounterActivity();
    this.props.getactivitiesCalendar(userId)
    this.props.clearActivityDataOnClose();
    this.props.clearPokeFormData()
    this.props.selectActivityId(null);
    if (!this.props.clientPanel) {
      localStorage.setItem('selectedOrgId', orgId);
    }
    this.props.handleClose();
    
  }

  refreshWorkflowExecutionHistoryDetail = () => {
    const { workflowExecutionDetail, subWorkflowDetailData, selectedMenuItem, clientPanel} = this.props;
    
    const isWorkflowDetailOpened = workflowExecutionDetail && Object.keys(workflowExecutionDetail).length;
    const isSubWorkflowDetailOpened = subWorkflowDetailData && Object.keys(subWorkflowDetailData).length;
    const userId = clientPanel ? localStorage.getItem("uid") : localStorage.getItem("auth_uid");
    if(isSubWorkflowDetailOpened) {
      const {id: executionId, workflowId: subWorkflowId, organization: orgId} = subWorkflowDetailData;
      if(selectedMenuItem == 1) {
        this.props.getSubWorkflowHistoryDetail({subWorkflowId, orgId, executionId})
      } else {
        this.props.getUserSubWorkflowHistoryDetail({subWorkflowId, userId, orgId, executionId})
      }
    } else if(isWorkflowDetailOpened) {
      const {id: executionId, workflowId, organization: orgId} = workflowExecutionDetail;
      if (selectedMenuItem == 1) {
        this.props.getWorkflowHistoryDetail({ workflowId, orgId, executionId})
      } else {
        this.props.getUserWorkflowHistoryDetail({workflowId, userId, orgId, executionId}) 
      }
    } 
  }

  hanldeCloseExecuteEncounterActivity = () => {
    const {executeEncounterActivity} = this.props;
    if(executeEncounterActivity) {
      const auth_uid = localStorage.getItem('auth_uid');
      localStorage.setItem('uid', auth_uid);
      localStorage.setItem("selectedOrgId", user.organization)
      this.props.setSelectedMemberId(null);
      this.props.handleExecuteEncounterActivity(false);
    }
  }
  handleCompletionActivity = () => {
    const {activity, stepslist, stepsCompletionList, activityMessage, shouldTakePicture, selfEvaluationFormId } = this.props;
    if (activity && activity.routineCompletionRewardUrl && validURL(activity.routineCompletionRewardUrl)) {
      // window.open(activity.routineCompletionRewardUrl, '_blank')
      var windowReference = window.open();
      windowReference.location = activity.routineCompletionRewardUrl;
      openPopup(windowReference)
    }
    this.handlecloseAlert();
  }

  handleRewards = () => {
    let achievementId = this.props.startActivity.achievementId
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    let uid = localStorage.getItem('uid');
    let orgId = localStorage.getItem('selectedOrgId') || localStorage.getItem('orgId');
    this.props.EndActivity(achievementId, date, uid,null, null, orgId)
    this.props.selectActivityId(null)
    this.props.handleRewards()
  }

  handleListDoneStep = (stepId, stepNo, stepResponseData) => {
    const { stepslist, executeActivity, stepsCompletionList } = this.props;
    const currentStep = stepslist ? stepslist.find(step => step.stepId == stepId) : {};
    let currentCompletedStep = stepsCompletionList.length > 0 && stepsCompletionList.find(ele => ele.step.id == stepId) ? stepsCompletionList.find(ele => ele.step.id == stepId) : {};
    console.log(" currentStep --> ", currentStep);
    const takePictureMessage = currentStep && currentStep.takePictureMessage ? currentStep.takePictureMessage : "";
    if (currentStep && currentStep.isStartButtonEnabled && currentCompletedStep.status != "Started") {
      this.handleStartStep(stepId, stepNo);
    } else {
      // if(currentStep.postStepFormId && stepResponseData && stepResponseData.postStepFormResponse) {
      //   this.handleEndStep(stepId, stepNo,  stepResponseData)
      // } else if(currentStep.postStepFormId) {
      //   console.log(" Opening post step form...");
      //   this.props.getPokeForm(currentStep.postStepFormId)
      //   this.setState({ showForm: true, stepId: stepId, stepFormId: null, stepResponseData: stepResponseData})
      // } 
      if (executeActivity && currentStep.shouldTakePicture) {
        if (stepResponseData && stepResponseData.photoUrl) {
          this.handleEndStep(stepId, stepNo, stepResponseData);
        } else {
          this.setState({ showPictureCamera: true, stepId: stepId, stepResponseData: stepResponseData, takePictureMessage: takePictureMessage });
        }
      } else {
        this.handleEndStep(stepId, stepNo, stepResponseData);
      }
    }
  }

  handleNextStep = (stepId, stepNo, stepResponseData) => {
    const { stepslist, executeActivity, stepsCompletionList } = this.props;
    const currentStep = stepslist ? stepslist.find(step => step.stepId == stepId) : {};
    let currentCompletedStep = stepsCompletionList.length > 0 && stepsCompletionList.find(ele => ele.step.id == stepId) ? stepsCompletionList.find(ele => ele.step.id == stepId) : {};
    console.log(" currentStep --> ", currentStep);
    // if(currentStep && currentStep.shouldTakePicture && executeActivity) {
    //   console.log(" should take picture");
    //   this.props.handleShowPhotoCamera(true);
    // }
    const takePictureMessage = currentStep && currentStep.takePictureMessage ? currentStep.takePictureMessage : "";
    if (currentStep && currentStep.isStartButtonEnabled && currentCompletedStep.status != "Started") {
      this.handleStartStep(stepId, stepNo);
    } else {
      // if(currentStep && currentStep.postStepFormId && stepResponseData && stepResponseData.postStepFormResponse) {
      //   this.handleEndStep(stepId, stepNo,  stepResponseData)
      // } else if(currentStep.postStepFormId) {
      //   console.log(" Opening post step form...");
      //   this.props.getPokeForm(currentStep.postStepFormId)
      //   this.setState({ showForm: true, stepId: stepId, stepFormId: null, stepResponseData: stepResponseData})
      // } 
      if (executeActivity && currentStep && currentStep.shouldTakePicture) {
        if (stepResponseData && stepResponseData.photoUrl) {
          this.handleEndStep(stepId, stepNo, stepResponseData);
        } else {
          this.setState({ showPictureCamera: true, stepId: stepId, stepResponseData: stepResponseData, takePictureMessage: takePictureMessage, courseOpened: false });
        }
      } else {
        this.handleEndStep(stepId, stepNo, stepResponseData);
      }
    }

  }
  handleStartStep = (stepId) => {
    const { startActivity } = this.props;
    let id = startActivity.achievementId;
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    let uid = localStorage.getItem('uid');
    let orgId = localStorage.getItem('selectedOrgId') || localStorage.getItem('orgId');
    this.props.StartStep(id, date, stepId, uid, orgId);
  }
  handleEndStep = (stepId, stepNo, stepResponseData) => {
    const { startActivity, selfEvaluationFormId, stepslist, stepsCompletionList, viewType, shouldTakePicture, executeActivity, takePictureMessage } = this.props;
    let id = startActivity.achievementId;
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z');
    let orgId = localStorage.getItem('selectedOrgId') || localStorage.getItem('orgId');
    let uid = localStorage.getItem('uid');
    if (viewType === "List") {
      stepNo = this.state.stepNo;
    }
    const stepsCompletedList = stepsCompletionList.length > 0 ? stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped") : [];
    const stepsCompleted = stepsCompletedList.length + 1;
    console.log(" stepsCompletionList --> ", stepsCompletionList);
    let endActivity = false;
    if (stepslist.length == stepsCompleted) {
      if (shouldTakePicture && executeActivity) {
        this.setState({ showPictureCamera: true, stepFormId: null, stepId: null, takePictureMessage: takePictureMessage });
      } else if (selfEvaluationFormId) {
        //console.log(" Opening Activity poke form...");
        //this.props.getPokeForm(selfEvaluationFormId)
        // this.setState({ countDownInterval: 0 ,showForm: true, stepFormId: null, stepId: null, selectedFormId: selfEvaluationFormId })
      } else {
        endActivity = true;
      }
    } else {
      clearInterval(this.intervalID1);
    }
    if (stepResponseData && stepResponseData.photoUrl && stepResponseData.photoUrl === "Camera Not Supported") {
      delete stepResponseData.photoUrl;
    }
    this.props.EndStep(id, date, stepId, uid, stepResponseData, endActivity, orgId);
    this.setState({ countDownInterval: 0, countDownStart: false, stepNo: stepNo + 1, responseSubmitted: false, stepResponseData: null, courseOpened: false })
  }
  handleEndAchievement = (evaluationFormData, photoUrl) => {
    const { startActivity, achievement } = this.props;
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    //let routineId = achievement && achievement.routineId ? achievement.routineId : '';
    let uid = localStorage.getItem('uid');
    let orgId = localStorage.getItem('selectedOrgId') || localStorage.getItem('orgId');
    let id = startActivity.achievementId;
    let endActivityResponse = {};
    if (evaluationFormData) {
      endActivityResponse.formResponse = evaluationFormData;
    }
    if (photoUrl && photoUrl !== "Camera Not Supported") {
      endActivityResponse.confirmationPictureUrl = photoUrl;
    }
    if (achievement && achievement.routine.isTimerEnabled) {
      this.props.EndActivity(id, date, uid, endActivityResponse, this.state.timerTime, orgId)
    } else {
      this.props.EndActivity(id, date, uid, endActivityResponse, null, orgId)
    }
  }
  handleClosePictureCamera = (photoUrl) => {
    let { stepId, stepNo, stepResponseData } = this.state;
    const { selfEvaluationFormId, shouldTakePicture } = this.props;
    if (stepId) {
      if (stepResponseData) {
        stepResponseData.photoUrl = photoUrl;
      } else {
        stepResponseData = { photoUrl: photoUrl }
      }
      this.setState({ showPictureCamera: false });
      this.handleNextStep(stepId, stepNo, stepResponseData);
    } else {
      if (selfEvaluationFormId) {
        //this.props.getPokeForm(selfEvaluationFormId)
        this.setState({ showPictureCamera: false, showForm: true, selectedFormId:selfEvaluationFormId, stepFormId: null, stepId: null, photoUrl: photoUrl, takePictureMessage: null })
      } else {
        this.handleEndAchievement(null, photoUrl);
        this.setState({ showPictureCamera: false, photoUrl: photoUrl, takePictureMessage: null });
      }
    }
  }
  handleSkipStep = (stepId, stepNo) => {
    const { stepslist, selfEvaluationFormId, stepsCompletionList, startActivity, viewType, shouldTakePicture, executeActivity, takePictureMessage } = this.props;
    let id = startActivity.achievementId;
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    let uid = localStorage.getItem('uid');
    let orgId = localStorage.getItem('selectedOrgId') || localStorage.getItem('orgId');
    if (viewType === "List") {
      stepNo = this.state.stepNo;
    }
    const currentStep = stepslist ? stepslist.find(step => step.stepId == stepId) : {};
    console.log(" currentStep --> ", currentStep);
    console.log(" stepsCompletionList --> ", stepsCompletionList);
    const stepsCompleted = stepsCompletionList.length + 1;
    let endActivity = false;
    if (stepslist.length == stepsCompleted) {
      if (shouldTakePicture && executeActivity) {
        this.setState({ showPictureCamera: true, stepFormId: null, stepId: null, takePictureMessage: takePictureMessage });
      } else if (selfEvaluationFormId) {
        console.log(" Opening Activity evaluation form...");
        //this.props.getPokeForm(selfEvaluationFormId)
        this.setState({ countDownInterval: 0, showForm: true, stepFormId: null, selectedFormId:selfEvaluationFormId, stepId: null, stepNo: stepNo + 1 })
      } else {
        endActivity = true;
      }
    } else {
      clearInterval(this.intervalID1)
    }
    this.props.SkipStep(id, date, stepId, uid, endActivity, orgId);
    this.setState({ countDownInterval: 0,  countDownStart: false, stepNo: stepNo + 1, responseSubmitted: false, stepResponseData: null, courseOpened: false });
  }

  handleHelpVideoStep = (stepId) => {
    const { stepslist } = this.props;
    const currentStep = stepslist ? stepslist.find(step => step.stepId == stepId) : {};
    this.props.handleShowHelpPanel({show: true, currentStep: currentStep});
  }


  handleSaveAndContinue = (payloadData) => {
    let {stepId, stepNo, nextStep, stepFormId, stepResponseData, photoUrl } = this.state;
    let {executeActivity, stepslist, viewType, startActivity, authUserName, groups, stepsCompletionList } = this.props;
    let uid = localStorage.getItem('uid');

    if (!stepResponseData) {
      stepResponseData = {}
    }
    const organizaiton = localStorage.getItem("selectedOrgId");

    groups = this.props.selectedGroup && this.props.selectedGroup.length > 0 ? this.props.selectedGroup : groups

    if (payloadData && payloadData.formName === 'Poke') {
      if (stepslist.length == stepsCompletionList.length || stepslist.length === 0) {
        this.handleEndAchievement(payloadData, photoUrl);
      } else if (this.props.isPokeForGroup) {
        this.props.setPokeFormForGroup(groups, payloadData)
      } else {
        this.props.savePokrFormForClients(uid, payloadData, this.props.selectedActivitiesHistory && this.props.selectedActivitiesHistory, null, organizaiton)
      }
    } else if (stepFormId) {
      stepResponseData.cardFormResponse = payloadData;
    } else {
      this.handleEndAchievement(payloadData, photoUrl);
    }

    this.props.clearPokeFormData();

    if (viewType == "List") {
      this.setFormStepEnabled();
      this.setState({ showForm: false, selectedFormId:null, nextStep: false, evaluationData: null, showSelfEvaluationform: Boolean(stepFormId)})
      if (stepId && stepslist[this.state.stepIndex].cardType !== "Course") {
        if (stepResponseData && (stepResponseData.cardFormResponse || stepResponseData.postStepFormResponse)) {
          this.handleListDoneStep(stepId, this.state.stepIndex, stepResponseData)
        }
      }
    } else {
      this.setState({ showForm: false, selectedFormId:null, nextStep: false, evaluationData: null, showSelfEvaluationform: Boolean(stepFormId), responseSubmitted: true, nextdisable: false })
      if (stepResponseData && (stepResponseData.cardFormResponse || stepResponseData.postStepFormResponse)) {
        this.handleNextStep(stepId, stepNo, stepResponseData);
      }
    }
  }

  handleUpdateStatus = () => {
    this.handleEndAchievement();
  }

  setFormStepEnabled = () => {
    let s = { ...this.state };
    let index = s.stepsDisabled.findIndex(li => li == s.activeStep);
    if (index >= 0) {
      s.stepsDisabled.splice(index, 1);

    }
    s.showForm = false;
    s.activeStep = -1;
    s.nextStep = false;
    s.stepId = null
    this.setState(s);
  }

  // handleCancel(){
  //     this.props.handleClose()
  // }

  handleTimeOut() {
    clearInterval(this.intervalID);
    this.setState({ timeOutAlert: !this.state.timeOutAlert })
  }

  handleShowForm = (stepId, stepFormId, stepIndex) => {
    console.log(" Opening Activity poke form --> ", stepId);
    this.setState({ showForm: true, stepIndex: stepIndex, selectedFormId:stepFormId, responseSubmitted: false, stepId: stepId, stepFormId: stepFormId, nextStep: false })
  }

  handlecountDownInterval = (cardType) => {
    this.setState({ countDownStart: true })
    if (this.state.countDownInterval > 0) {
      this.intervalID1 = setInterval(
        () => {
          this.setState({
            countDownInterval: this.state.countDownInterval - 1
          })
          if (cardType === "countDownEnforced" && this.state.countDownInterval <= 1) {
            this.setState({nextdisable: false})
            clearInterval(this.intervalID1);
          } else if (this.state.countDownInterval <= 1) {
            clearInterval(this.intervalID1);
          }
        },
        1000
      );
    } else {
      if (cardType === "countDownEnforced") {
        this.setState({nextdisable: false})
      }
    }
   
  }

  handleShowRewards = (activity) => {
    if (activity.rewards > 0) this.setState({ showRewards: true })
    else this.handleEndActivity()
    //activity.rewards>0?()=>()=>this.setState({showRewards:true}):this.handleEndActivity()
  }

  updateTimer = (value) => {
    this.setState({ countDownInterval: value, countDownStart: false })
  }

  handleEvent = () => {
    let { eventDate } = this.state
    eventDate = Moment().format('MMM DD, YYYY')
    this.setState({ showEvent: true, eventDate })
  }
  handleCloseEvent = () => {
    this.setState({ showEvent: false, responseSubmitted: true })
  }
  nextDate = () => {
    let { eventDate } = this.state
    let next = Moment(eventDate).add(1, 'days').format('MMM DD, YYYY')
    this.setState({ eventDate: next })
  }

  backDate = () => {
    let { eventDate } = this.state
    let next = Moment(eventDate).add(-1, 'days').format('MMM DD, YYYY')
    this.setState({ eventDate: next })
  }

  handleStartDateChange(date) {
    //console.log('handleStartDateChange',date)
    let event = Moment(date).format('MMM DD, YYYY')
    this.setState({ eventDate: event })
  }

  disableNext = () => {
    this.setState({ nextdisable: !this.state.nextdisable })
  }
  handleNextEnable = () => {
    this.setState({ nextdisable: false })
  }
  handleNextDisable = () => {
    this.setState({ nextdisable: true })
  }

  openSelfEvaluationForm(selfEvaluationFormId) {
    console.log(" Opening Activity self evaluation form...");
    //this.props.getPokeForm(selfEvaluationFormId);
    this.setState({ showForm: true, nextStep: true, stepFormId: null, stepId: null, showSelfEvaluationform: false, selectedFormId: selfEvaluationFormId })
  }

  //To Manage Client Status Form
  handelStatusFormClose = () => {
    this.setState({ StatusForm: false })
  }

  showOpenCourseAlert = (stepId, stepIndex, url, viewType) => {
    // this.setState({ ...this.state, openCourseAlert: true, nextdisable: !this.state.nextdisable});
    const {stepslist} = this.props;
    console.log("%c Open course alert displayed", "color:red");
    if (this.props.executeActivity) {
      this.setState({ ...this.state, stepId, stepIndex, nextdisable: false, courseOpened: true })
      if (viewType == "List") {
        this.handleListDoneStep(stepId, stepIndex)
      }
      const currentStep = stepslist ? stepslist.find(step => step.stepId == stepId) : {};
      console.log("currentStep :", currentStep);
      if(currentStep.courseId) {
          this.props.handleShowActivityCourse({show: true, courseId: currentStep.courseId})
      }
     // window.open(url, '_blank')
      // var windowReference = window.open();
      // windowReference.location = url
      // openPopup(windowReference)
    } else {
      this.setState({ ...this.state, openCourseAlert: true, stepId, stepIndex, nextdisable: false })
    }
  }

  
  handleApiErrorAlertClose = () => {
    let { apiError } = this.props;
    console.log(" api error in method -->", apiError);
    if (apiError && apiError.type != "wpq") {
      this.handleCloseActivityPopup();
    }
    this.props.removeAPIError(null);
  }
  handleCloseCoursePopup() {
    this.setState({ ...this.state, openCourseAlert: false })
    // this.handleListDoneStep(this.state.stepId, this.state.stepIndex)
    let { startActivityDetails } = this.props;
    let viewType = startActivityDetails && startActivityDetails.activity && startActivityDetails.activity.routineViewType ? startActivityDetails.activity.routineViewType : "Flow";
    if (viewType == "List") {
      this.handleListDoneStep(this.state.stepId, this.state.stepIndex)
    } else {

    }
  }

  handleOpenPokeFormHelpURL = (helpURL) => {
    if (helpURL == "lifesherpapp.com") {
      helpURL = "https://lifesherpapp.com"
    }
    // window.open(helpURL, '_blank');
    var windowReference = window.open();
    windowReference.location = helpURL
    openPopup(windowReference)
  }
  slideEnded = () => {
    const {activity} = this.props;
    if (document.getElementsByClassName("rs-play")[0] && activity.autoTTSEnabled) {
      document.getElementsByClassName("rs-play")[0].click();
    }
  }

  getStepIndexNextToComplete(stepsCompletionList, stepsList) {
    const {stepNo} = this.state
    let stepIndex = 0
    if(stepsCompletionList && stepsCompletionList.length > 0) {
      const stepsCompletedList = stepsCompletionList.map(stepCompleted => {
        if(stepCompleted.status == "Done" || stepCompleted.status == "Skipped") {
          return stepCompleted.step.position
        } 
      }) || [];
      if (stepsCompletedList.length > 0) {
        const stepsleft = stepsList.filter(step => !stepsCompletedList.includes(step.position)) || []
        stepIndex = stepsleft && stepsleft.length > 0 ? stepsleft[0].position - 1 : stepNo
        // console.log("***************   stepsleft    **************: ", stepsleft, stepsCompletedList, stepIndex)
      }
    }
    return stepIndex
  }


  render() {
    let { stepNo, showForm, timerTime, timeOutAlert, showRewards, StatusForm, openCourseAlert, showPictureCamera, course_completion_status } = this.state
    let { authUserImage, authUserName, clientImage, clientName, stepSliderLoading, apiError, showHelpPanel, showActivityCourse } = this.props;
    let { activity, stepCount, achievement, selfEvaluationFormId, stepslist, stepsCompletionList, statusData, viewType, themeData, executeActivity, showSetStatusPopup} = this.props;
    //console.log("achievement ----> ", achievement);
    // if (this.state.countDownInterval <= 0) clearInterval(this.intervalID1);
    let stepWidth = stepslist ? 200 / stepslist.length : 200;
    // Finding position of current Step --
    let stepsCompletedList = stepsCompletionList.length > 0 ? stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped") : [];
    let position = stepsCompletedList.length;
    stepNo = position && stepNo < position ? position : stepNo; // this.getStepIndexNextToComplete(stepsCompletionList, stepslist)
    // Getting flow view current Step completion details
    let currentCompletedStep = stepslist && stepslist[stepNo] && stepsCompletionList && stepsCompletionList.find(ele => ele.step.id == stepslist[stepNo].stepId) ? stepsCompletionList.find(ele => ele.step.id == stepslist[stepNo].stepId) : {};
    const ind = this.getStepIndexNextToComplete(stepsCompletionList, stepslist)
    const auth_uid = localStorage.getItem('auth_uid');
    const uid = localStorage.getItem('uid');
    const themeLogo = localStorage.getItem("loginLogoImage") || this.props.themeLogo;
    let activeTheme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : { id: 1, name: "primary" };
    const image = auth_uid === uid ? authUserImage : clientImage;
    const name = auth_uid === uid ? authUserName : clientName;
    // Getting activity expected duration
    //console.log("pokeForm",this.props.pokeForm);
    const expectedDuration =  achievement && achievement.routine && achievement.routine.expectedDuration ? achievement.routine.expectedDuration : null
    const expectedDurationString = expectedDuration ? `[${expectedDuration}]` : ""
    let openPopup = true;
    if(apiError && apiError.type != "wpq") {
      openPopup = false
    }
    const skipButton = activity && activity.showSkipButton && stepNo < stepslist.length && !stepslist[stepNo].isMandatory ? true: false;
    
    return (
      <div className="activity-previev-height" data-testid="activity-preview-dialog">
        {showHelpPanel && <ActivityHelpContacts handleclosePanel={this.handlecloseAlert}/>}
        {showActivityCourse && <ActivityCourse course_completion_status={course_completion_status} handlePreviousCourseStatus={this.handlePreviousCourseStatus.bind(this)} title={activity && activity.title} handleclosePanel={this.handlecloseAlert}/>}
        <Dialog
          fullScreen={false}
          open={/*!showHelpPanel && */!showActivityCourse && openPopup}
          // onClose={() => this.handlecloseAlert()}
          fullWidth={true}
          maxWidth="xs"
          aria-labelledby="responsive-dialog-title"
          className={`${this.props.popupThemeClass} activity-preview-dialog-popup`}
        >
          <DialogTitle>
            <div className="d-flex align-items-center template-header justify-content-between pl-3 pr-2 py-2 bg-lifesherpa">
              <Label data-testid="execute-activity-popup-title" className="listItemTitle mb-0 text-white">{executeActivity ? (activity && activity.title ? `${activity.title} ${expectedDurationString}` : '') : `${activity && activity.title ? activity.title + " " : ''}(Preview)`}</Label>
              <IconButton className="text-white" data-testid="close-execute-activity-panel" onClick={() => this.handlecloseAlert()}><CloseIcon /></IconButton>
            </div>
          </DialogTitle>
          <DialogContent className={viewType == "List" && (stepsCompletionList.length == 0 || stepsCompletionList.length !== stepslist.length) && !this.props.pokeForm ? "list-view-container activity-preview-dialog-content  " : " activity-preview-dialog-content"}>
            <LifeSherpaLoading loading={this.props.loading1} />
            {showForm &&
              <FormPage
                image={image}
                name={name}
                formId={this.state.selectedFormId}
                disabled={this.props.disabled}
                handleSaveAndContinue={this.handleSaveAndContinue}
                executeActivity={executeActivity}
              />
            }
            {/*!showHelpPanel && */!this.state.showEvent && !this.state.addEvent && !showForm &&
              <div className={"container "}>
                {activity.isTimerEnabled && !showRewards && stepNo < stepslist.length &&
                  <AchievementTimer
                    achievement={achievement}
                    timerTime={this.state.timerTime}
                  />
                }
                {/* {activity.isTimerEnabled && !showRewards && stepNo < stepslist.length && <div className="row p-2 border-bottom border-top bg-lifesherpa">
                  <h2 className="text-white my-auto mx-auto">{'+' + hours + ':' + minutes + ':' + seconds}</h2>
                </div>}
                {activity.isTimerEnabled && !showRewards && stepNo < stepslist.length && <div className="row p-2 border-bottom bg-lifesherpa">
                  <p className="text-white my-auto ml-1">{'Elapsed Time ' + hours + ':' + minutes + ':' + seconds}</p>
                </div>} */}
                {viewType == "List" && (stepsCompletedList.length == 0 || stepsCompletedList.length !== stepslist.length) ?
                  stepslist.map((step, key) =>
                    <div data-testid={`activity-list-view-step${key + 1}`} className="row activity-step-list-view" key={key + "activitydetailsmain"}>
                      <div className="w-100 ">
                        <StartActivityListViewDetails
                          step={step}
                          key={key + "activitydetails"}
                          stepIndex={key}
                          openedStep={this.state.openedStep}
                          stepKey={stepCount[stepNo]}
                          countDownStart={this.state.countDownStart}
                          countDownInterval={this.state.countDownInterval}
                          activity={activity}
                          stepNo={stepNo}
                          stepsCompletionList={stepsCompletionList}
                          stepslist={stepslist}
                          checkList={this.state.checkList}
                          stepsDisabled={this.state.stepsDisabled}
                          updateCheckList={this.updateCheckList}
                          setActiveStep={this.setActiveStep}
                          setStepDisabled={this.setStepDisabled}
                          setStepEnabled={this.setStepEnabled}
                          nextdisable={this.state.nextdisable}
                          handleNextStep={this.handleListDoneStep}
                          handleSkipStep={this.handleSkipStep}
                          disableNext={() => this.disableNext()}
                          handleEvent={() => this.handleEvent()}
                          showForm={this.handleShowForm}
                          handlecountDownInterval={this.handlecountDownInterval}
                          updateTimer={(value) => this.updateTimer(value)}
                          openedItem={this.state.openedItem}
                          setOpenSteps={this.setOpenSteps}
                          showOpenCourseAlert={this.showOpenCourseAlert}
                          image={image}
                          name={name}
                          executeActivity={executeActivity}
                          isTimerEnabled={activity.isTimerEnabled}
                          achievement={achievement}
                          viewType={viewType}
                          handleHelpVideoStep={this.handleHelpVideoStep}
                        />
                      </div>
                    </div>)
                    : viewType == "Flow" && stepslist && stepNo < stepslist.length && !stepSliderLoading ?
                    <Slide direction={"left"} in={!stepSliderLoading} timeout={1000} addEndListener={this.slideEnded}>
                      <div data-testid="activity-steps-in-flow-view" key={stepNo} className="activity-details-height">
                        <StartActivityDetails
                          step={stepslist[stepNo]}
                          stepKey={stepNo + 1}
                          countDownStart={this.state.countDownStart}
                          countDownInterval={this.state.countDownInterval}
                          disableNext={() => this.disableNext()}
                          handleEvent={() => this.handleEvent()}
                          showForm={this.handleShowForm}
                          handlecountDownInterval={this.handlecountDownInterval}
                          updateTimer={(value) => this.updateTimer(value)}
                          showOpenCourseAlert={this.showOpenCourseAlert}
                          image={image}
                          name={name}
                          executeActivity={executeActivity}
                          handleNextEnable={this.handleNextEnable}
                          handleNextDisable={this.handleNextDisable}
                          stepsCompletionList={stepsCompletionList}
                          responseSubmitted={this.state.responseSubmitted}
                          isTimerEnabled={activity.isTimerEnabled}
                          achievement={achievement}
                          viewType={viewType}
                        />
                      </div>
                    </Slide>
                  : stepslist && stepNo < stepslist.length && viewType == "Flow" ?
                    <div className="activity-details-height">
                      <LifeSherpaLoading loading={this.props.loading1} />
                    </div>
                    :  <div>
                    {showForm ? <div className="activity-details-height"></div>
                      :
                      <div className="col-12 complete-activity-page-height mt-60 mx-auto">
                        {!this.props.loading1 && executeActivity && activity.rewards ?
                          <>
                            <h2 data-testid="activity-steps-not-found" className="text-center text-dark mt-5 margin-t30">
                              {`Great job! You have added ${activity.rewards} gem(s) to your collection.`}
                              {activity.confirmationRewards ? ` You will receive additional ${activity.confirmationRewards} gem(s) when your accomplishment is confirmed` : ""}
                            </h2>
                          </>
                          : !this.props.loading1 && activity && activity.title &&
                          <h2 data-testid="activity-steps-not-found" className="text-center text-dark mt-5 margin-t30">
                            {`Congratulations! for completing routine ${activity && activity.title ? activity.title.toUpperCase() : ''}.`}
                            {activity.confirmationRewards ? ` You will receive ${activity.confirmationRewards} gem(s) when your accomplishment is confirmed` : ''}
                          </h2>
                        }
                        {activeTheme && (activeTheme.name == 'primary' || activeTheme.name == 'Default') ?
                          <div className="mx-auto d-flex mt-50">
                            <img className="auto-resize-img" style={{maxWidth: "100%", maxHeight: 120}} height="auto" width="auto" src={themeLogo || SherpaLogo} />
                          </div>
                          :
                          <div className="mx-auto container text-center mt-50 activity-preview-theme-logo">
                            <img className="auto-resize-img" style={{maxWidth: "100%", maxHeight: 120}} height="auto" width="auto" src={themeLogo || SherpaLogo} />
                            <div className="d-flex align-items-center justify-content-center lifesherpa-poweredby-logo">
                              {themeData && themeData.loginLifeSherpaImage &&
                                <img src={themeData.loginLifeSherpaImage} alt="session-logo" className="" width="auto" height="30" />
                              }
                            </div>
                          </div>
                        }
                        <div className="d-flex mt-auto mb-4">
                          {executeActivity &&
                            <Button color="primary" data-testid="activity-preview-completed" className="mx-auto primary-button" onClick={() => this.handleCompletionActivity()}>OK</Button>
                          }
                        </div>
                      </div>
                    }
                  </div>
                }
               
                {!stepslist.length  && StatusForm && showSetStatusPopup &&
                    <SetCurrentStatusForm
                      image={image}
                      name={name}
                      statusData={statusData}
                      executeActivity={executeActivity}
                      handelStatusFormClose={this.handelStatusFormClose}
                      handleUpdateStatus={this.handleUpdateStatus}
                    />
                }
              </div>
            }
            {showPictureCamera && <SherpaCamera userName={name} takePictureMessage={this.state.takePictureMessage} handleClosePictureCamera={this.handleClosePictureCamera} />}
            {this.state.closeAlert &&
              <div className="col-12 p-4">
                <SweetAlert
                  warning
                  showCancel
                  btnSize="sm"
                  customClass="warningText"
                  confirmBtnText="YES"
                  cancelBtnText="NO"
                  confirmBtnBsStyle="warning"
                  cancelBtnBsStyle="default"
                  title="Resume"
                  onConfirm={() => this.handleClose()}
                  onCancel={() => this.handleCancel()}
                >
                  <div className="sweet-alert-text">Would you like to resume later?</div>
                </SweetAlert>
              </div>
            }
            {timeOutAlert &&
              <div className="col-12 p-4">
                <SweetAlert
                  warning
                  btnSize="sm"
                  customClass="warningText"
                  confirmBtnText="OK"
                  confirmBtnBsStyle="warning"
                  title="TimeOut"
                  onConfirm={() => this.handleCancel()}
                  onCancel={() => this.handleCancel()}
                >
                  <div className="sweet-alert-text">Activity Timeout</div>
                </SweetAlert>
              </div>
            }
            {(openCourseAlert || apiError) &&
              <div className="col-12 p-4">
                <SweetAlert
                  warning
                  btnSize="sm"
                  customClass="warningText"
                  confirmBtnText="OK"
                  confirmBtnBsStyle="warning"
                  title={apiError ? apiError.response ? apiError.response.data.message : apiError.message : "Course cannot be opened in activity preview"}
                  onConfirm={() => apiError ? this.handleApiErrorAlertClose() : this.handleCloseCoursePopup()}
                >
                  <div className="sweet-alert-text"></div>
                </SweetAlert>
              </div>
            }
          </DialogContent>
          {!showHelpPanel && !this.state.showEvent && !this.state.addEvent && (!showForm) && stepslist && stepNo < stepslist.length && viewType == "Flow" &&
            <DialogActions className="activity-preview-dialog-actions">
              <div className="container" >
                {stepslist && stepNo < stepslist.length && viewType == "Flow" && <div data-testid="steps-completion-progress-bar" className="d-flex step-complete-bar mx-auto align-items-center my-3">
                  <div className="activity-completed " style={{ width: (stepWidth * (stepNo + 1)) + 'px' }}></div>
                  <div className="activity-remain" style={{ width: (200 - stepWidth * (stepNo + 1)) + 'px' }}></div>
                </div>}
                {stepslist && stepNo < stepslist.length && viewType == "Flow" &&
                  <div className=" row pb-2 activity-preveiw-skip-buttons m-0">
                    <div className={skipButton ? "col-4" : "col-0"}>{activity && activity.showSkipButton && !stepslist[stepNo].isMandatory ?
                      <div className='d-flex align-items-center justify-content-center'><Button title="Skip Step" data-testid="skip-step-button" color="primary" variant="outlined" className=" mx-auto skip-button"
                        disabled={stepNo >= stepslist.length} onClick={() => this.handleSkipStep(stepslist[stepNo].stepId, stepNo)}>SKIP IT</Button></div> : ''}
                    </div>
                    <div className={skipButton ? "col-4" : "col-6"}>
                      <div className='d-flex align-items-center justify-content-center'><IconButton title="Open Help Page" onClick={() => this.handleHelpVideoStep(stepslist[stepNo].stepId, stepNo)}><img className="flow-layout-help-image" width={50} height={50} src={require("../../../assets/img/help-logo.png")} /></IconButton></div>
                    </div>
                    <div className={skipButton ? "col-4" : "col-6"}>
                      <div className='d-flex align-items-center justify-content-center'><Button title="Next Step" data-testid="next-step-button" color="primary" variant="contained" disabled={this.state.courseOpened || (stepslist[stepNo].isStartButtonEnabled && currentCompletedStep.status != "Started") ? false : this.state.nextdisable} className="mx-auto next-button"
                        onClick={() => this.handleNextStep(stepslist[stepNo].stepId, stepNo)}>{stepslist[stepNo].isStartButtonEnabled && currentCompletedStep.status != "Started" ? 'START' : 'NEXT'}
                      </Button></div>
                    </div>
                  </div>}
              </div>
            </DialogActions>
          }
        </Dialog>
        {!openPopup &&
          <div className="col-12 p-4">
            <SweetAlert
              warning
              btnSize="sm"
              customClass="warningText"
              confirmBtnText="OK"
              confirmBtnBsStyle="warning"
              title={apiError.response ? apiError.response.data.message : apiError.message }
              onConfirm={() => this.handleApiErrorAlertClose()}
            >
              <div className="sweet-alert-text"></div>
            </SweetAlert>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ activitiesList, chatAppReducer, PokeForm, authUser, GroupList, settings, MyCourses, clientsList, workflowData }) => {
  const { popupThemeClass, themeLogo, selectedMenuItem } = settings;
  const { selectedMemberId, selectedRole, showHelpPanel, selectedMember} = chatAppReducer
  const { pokeForm, isPokeForGroup } = PokeForm
  const { groupMembersDetails, groups, selectedGroup } = GroupList
  const { user, profile, credit, themeData, usersInfo, enableWorkflows } = authUser;
  let { clientPanel } = clientsList;
  const { showActivityCourse } = MyCourses;
  const { startActivity, startActivityDetails, loading1, activityTimer, stepSliderLoading, executeActivity, apiError, activitiesAchievementsList, executeEncounterActivity, clientActivitiesType } = activitiesList
  const achievement = startActivity && startActivity.achievement;
  const activityMessage = startActivity ? startActivity.message : "";
  const stepsId = startActivityDetails ? startActivityDetails.stepsId : [];
  const activity = startActivityDetails ? startActivityDetails.activity : {};
  const stepCount = startActivityDetails ? startActivityDetails.stepCount : [];
  const viewType = activity && activity.routineViewType ? activity.routineViewType : "Flow";
  const achievementId = startActivity ? startActivity.achievementId : '';
  let currentInprogressAchievement = null;
  if (activitiesAchievementsList && executeActivity) {
    currentInprogressAchievement = activitiesAchievementsList.find(achievement => achievement.achievementId === achievementId);
  }
  if (currentInprogressAchievement && currentInprogressAchievement.routine) {
    achievement.routine = currentInprogressAchievement.routine;
  }
  let steps = achievement && achievement.routine ? achievement.routine.steps : {};
  let stepslist = [];
  if (steps) {
    Object.keys(steps).map((stepId, key) => {
      let obj = steps[stepId];
      obj.stepId = stepId;
      stepslist.push(obj);
    })
  }
  stepslist = stepslist.length > 0 ? stepslist.sort(function (a, b) { return a.position - b.position }) : []
  let stepsCompletion = achievement ? achievement.StepsCompletion : {};
  let stepsCompletionList = [];
  if (stepsCompletion) {
    Object.keys(stepsCompletion).map((stepCompId, key) => {
      stepsCompletionList.push(stepsCompletion[stepCompId]);
    })
  }
  // Get Steps by Completion Status
  if(viewType !== "List") {
    const completedSteps = stepslist.filter((step) => {
      const completedIndexStep = stepsCompletionList.findIndex(stepCompleted => (stepCompleted.status == "Done" || stepCompleted.status == "Skipped") && stepCompleted.step.id === step.stepId);
      return completedIndexStep !== -1;
    });
    const startedSteps = stepslist.filter((step) => {
      const completedIndexStep = stepsCompletionList.findIndex(stepCompleted => (stepCompleted.status == "Started") && stepCompleted.step.id === step.stepId);
      return completedIndexStep !== -1;
    });
    const unCompletedSteps = stepslist.filter((step) => {
      const completedIndexStep = stepsCompletionList.findIndex(stepCompleted => (stepCompleted.status == "Done" || stepCompleted.status == "Skipped" || stepCompleted.status == "Started") && stepCompleted.step.id === step.stepId);
      return completedIndexStep === -1;
    });
    stepslist = [...completedSteps, ...startedSteps, ...unCompletedSteps];
  } 
  
  if (currentInprogressAchievement && currentInprogressAchievement.StepsCompletion) {
    let RealtimeStepsCompletionList = [];
    Object.keys(currentInprogressAchievement.StepsCompletion).map((stepCompId, key) => {
      RealtimeStepsCompletionList.push(currentInprogressAchievement.StepsCompletion[stepCompId]);
    })
    stepsCompletionList = RealtimeStepsCompletionList;
  }
  // Getting Activity Form Data ---
  let formData = achievement && achievement.form ? achievement.form : {};
  let statusData = formData ? Object.values(formData)[0] : null;
  const activityType = (achievement && achievement.routine && achievement.routine.type) || (activity && activity.type);
  const selfEvaluationFormId = achievement && achievement.routine && achievement.routine.selfEvaluationFormId ? achievement.routine.selfEvaluationFormId : null;
  const showSetStatusPopup = (!selfEvaluationFormId || activityType === "QT-Set-Status" || activityType === "QT-Form") && statusData && statusData.formName == "Set your current status";
  const shouldTakePicture = achievement && achievement.routine && achievement.routine.shouldTakePicture ? achievement.routine.shouldTakePicture : null;
  const takePictureMessage = achievement && achievement.routine && achievement.routine.takePictureMessage ? achievement.routine.takePictureMessage : null;
  // Current User Details -----
  const selectedClient = groupMembersDetails && selectedMemberId ? groupMembersDetails.filter(client => client.userId === selectedMemberId)[0] : null
  let authUserImage = profile && profile.picture ? profile.picture : (usersInfo && usersInfo.profileImageURL) || require('Assets/avatars/user-15.jpg');
  let authUserName = profile ? profile.nickname || profile.name || (profile.given_name && profile.family_name ? profile.given_name + " " + profile.family_name : profile.given_name || profile.family_name) : 'Display Name'
  let clientImage = selectedClient && selectedClient.profileImageURL && selectedClient.name !== "Test User" ? selectedClient.profileImageURL : require('Assets/avatars/user-15.jpg');
  let clientName = selectedClient && selectedClient.name ? selectedClient.name : 'Client';
  const { workflowExecutionDetail, subWorkflowDetailData} = workflowData
  return {selectedMenuItem, workflowExecutionDetail, subWorkflowDetailData, showHelpPanel, stepSliderLoading, viewType, statusData, stepsCompletionList, stepslist, selfEvaluationFormId, stepsId, activity, stepCount, achievement, startActivity, startActivityDetails, pokeForm, loading1, user, profile, credit, activityTimer, isPokeForGroup, selectedMemberId, activityType, groupMembersDetails, authUserImage, authUserName, clientImage, clientName, popupThemeClass, themeLogo, themeData, executeActivity, apiError, activityMessage, achievementId, shouldTakePicture, takePictureMessage, executeEncounterActivity, showSetStatusPopup, showActivityCourse, groups, clientPanel, selectedGroup, clientActivitiesType, selectedMember, enableWorkflows };
}

export default connect(mapStateToProps, {
  getCourse,
  getWPQ,
  pauseAcivity,
  setSelectedStep,
  EndActivity,
  StartStep,
  EndStep,
  SkipStep,
  cancelActivity,
  getPokeForm,
  postStepsNotesForm,
  savePokrFormForClients,
  setPokeFormForGroup,
  selectActivityId,
  clearActivityDataOnClose,
  clearPokeFormData,
  getClientStatusDataById,
  getUserStatusById,
  handleShowPhotoCamera,
  removeAPIError,
  handleExecuteEncounterActivity,
  setSelectedMemberId,
  getactivitiesCalendar,
  setPokeFormData,
  handleShowHelpPanel,
  handleShowActivityCourse,
  clearCourseData,
  getActivitiesList,
  getActivitiesListN,
  setClientActivitiesType,
  showClientPanel, changeRole,
  getUserPendingWorkflowList,
  getUserSubWorkflowHistoryDetail,
  getSubWorkflowHistoryDetail,
  getUserWorkflowHistoryDetail,
  getWorkflowHistoryDetail
})(StartActivityListView);
